import React, { useEffect } from "react";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Error = () => {
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Error page",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>404 Not Found | RateMyMajor.io</title>
        <link rel="canonical" href="/error" />
        <meta
          name="description"
          content="Oops! The page you're looking for could not be found on RateMyMajor.io. Explore and choose the perfect major with RateMyMajor.io."
        />
        <meta
          name="keywords"
          content="404 Not Found, error page, RateMyMajor.io, explore majors, career guidance, student support"
        />
        <meta name="author" content="RateMyMajor.io" />

        {/* Open Graph meta tags */}
        <meta property="og:title" content="404 Not Found | RateMyMajor.io" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Oops! The page you're looking for could not be found on RateMyMajor.io. Explore and choose the perfect major with RateMyMajor.io."
        />
        {/* Replace 'url_to_your_thumbnail_image' with the actual image URL */}
        <meta
          property="og:image"
          content="/* Your image URL for the error page */"
        />
        {/* Replace 'url_to_your_page' with the actual page URL */}
        <meta property="og:url" content="https://ratemymajor.io/error" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="https://twitter.com/GabrielFono" />
        <meta name="twitter:title" content="404 Not Found | RateMyMajor.io" />
        <meta
          name="twitter:description"
          content="Oops! The page you're looking for could not be found on RateMyMajor.io. Explore and choose the perfect major with RateMyMajor.io."
        />
        {/* Replace 'url_to_your_thumbnail_image' with the actual image URL */}
        <meta
          name="twitter:image"
          content="/* Your image URL for the error page */"
        />
      </Helmet>
      <Layout>
        <Hero hero="defaultHero">
          <Banner
            title="Oops! 😢The page you're looking for could not be found. Explore and choose the perfect major with RateMyMajor.io"
            subtitle="you are currently on the error page, click below to be redirected to the home page"
          >
            <Link to="/" className="btn-primary">
              Go back Home
            </Link>
          </Banner>
        </Hero>
      </Layout>
    </>
  );
};
export default Error;
