import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import { NavLink } from "react-router-dom";

const data = [
  {
    id: uuid(),
    link: "/",
    name: "HOME 🏡",
    icon: "",
  },
  {
    id: uuid(),
    link: "/team",
    name: "Meet the Team 👨🏻‍💻✨",
    icon: "",
  },
];

const Links = () => {
  const [activeLink, setActiveLink] = useState("/");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    // Update activeLink when the component mounts
    const currentPath = window.location.pathname;
    setActiveLink(currentPath);
  }, []);

  return (
    <div className="container-nav-links">
      <ul className="nav-links">
        {data.map((item) => {
          const { id, link, name } = item;
          return (
            <li key={id}>
              <NavLink
                exact
                to={link}
                onClick={() => handleLinkClick(link)}
                className={activeLink === link ? "active-link" : ""}
              >
                <span>
                  {name}
                  {item.icon}
                </span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Links;
