import React, { useEffect } from "react";
import Links from "../constant/Links";
import { FaAlignJustify } from "react-icons/fa";
import { useState } from "react";
import Slider from "../components/Slider";
import Authentification from "../components/Authentification";
// import CreatePostDropdown from "../components/CreatePostDropdown";

import BtnSignDesktop from "../components/BtnSignDesktop";
import { useAuth0 } from "@auth0/auth0-react";
// import Warning from "../components/Warning";
// import CreatePostDropdown from "./CreatePostDropdown";
import uuid from "react-uuid";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [slideRight, setSlideRight] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const { isAuthenticated, logout, user } = useAuth0();

  const messages = [
    {
      id: uuid(),
      message:
        "💼 Post Job Openings: Find the perfect talent for your tech projects.",
    },
    {
      id: uuid(),
      message:
        "📝 Guest Post: Share your insights and grow your online presence.",
    },
    {
      id: uuid(),
      message:
        "📊 Premium Analytics: Offer in-depth data and insights for businesses to optimize their tech operations.",
    },
    {
      id: uuid(),
      message:
        "🛡️ Cybersecurity and SoftwareEngineering Training Courses: Offer premium courses to enhance users' cybersecurity skills and knowledge.",
    },
    {
      id: uuid(),
      message:
        "📰 Sponsored Content: Allow tech-related businesses to promote their products or services through sponsored posts.",
    },
    {
      id: uuid(),
      message:
        "📣 Ad Space: Sell advertising space on your platform to relevant tech companies.",
    },
    {
      id: uuid(),
      message:
        "📈 Data Privacy and Compliance Tools: Offer services and tools to help businesses comply with data protection regulations.",
    },
    {
      id: uuid(),
      message:
        "🏆 Hackathon and Coding Challenges: Organize meetup and coding challenges ",
    },
    {
      id: uuid(),
      message:
        "📚 Exclusive Tech Resources: Create a subscription-based library of exclusive tech resources, tutorials, and eBooks.",
    },
    {
      id: uuid(),
      message:
        "📰 Sponsored Content: Allow tech-related businesses to promote their products or services through sponsored posts.",
    },
    {
      id: uuid(),
      message:
        "📊 Premium Analytics: Offer in-depth data and insights for businesses to optimize their tech operations.",
    },
  ];

  const handleWarning = () => {
    setShowWarning((prev) => {
      return !prev;
    });
  };
  const handleVisibility = () => {
    setVisible((prev) => {
      return !prev;
    });
  };
  useEffect(() => {
    if (visible) {
      window.addEventListener("click", handleVisibility);
      return () => {
        window.removeEventListener("click", handleVisibility);
      };
    }
  }, [visible]);
  const changeNavbar = () => {
    if (window.scrollY >= 100 && window.scrollY <= 320) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);

  const handleButton = () => {
    setIsOpen((prev) => {
      return !prev;
    });
  };
  return (
    <>
      <div className="body-navbar">
        <div className="container-navBar">
          <nav className={navbar ? "navbar" : "navbar active-navbar"}>
            <div className="nav-center">
              <Authentification
                slideRight={slideRight}
                logout={logout}
                user={user}
                isAuthenticated={isAuthenticated}
                visible={visible}
                handleVisiblity={handleVisibility}
              />

              <Slider
                showSlider="showSlider"
                isOpen={isOpen}
                handleButton={handleButton}
              />
              <button type="button" className="nav-btn " onClick={handleButton}>
                <FaAlignJustify className="nav-icon" />
              </button>
              <div className="nav-header">
                <a href="/" className="container-logo">
                  <div className="container-logo-symbol">
                    <span>RM</span>
                  </div>
                  <div className="container-logo-title">
                    <span>RATEMYMAJOR</span>
                  </div>
                </a>
              </div>
              <Links />
              {/* <CreatePostDropdown /> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "fit-content",
                  /* width: "30%", */
                }}
              >
                {/* <span
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                title="We are currently implementing "
              >
                <span
                  style={{
                    position: "absolute",
                    fontSize: "10px",
                    bottom: "80%",
                    padding: "7px",
                    fontWeight: "800",
                    left: "70%",
                    background: "#fff",
                    border: "5px solid red",
                    color: "black",
                    width: "15px",
                    maxWidth: "fit-content",
                    maxheight: "fit-content",
                    height: "15px",
                    border: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: `translate(${-50}%, ${50}%)`,
                  }}
                >
                  2
                </span>
                <svg
                  style={{
                    width: "1em",
                    fill: "currentColor",
                    fontSize: "1.5rem",
                    flexShrink: "1.5rem",
                    userSelect: "none",
                    display: "inline-block",
                    height: "1em",
                    background: "transparent",
                    color: "white",
                    cursor: "pointer",
                    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  }}
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M10.01 21.01c0 1.1.89 1.99 1.99 1.99s1.99-.89 1.99-1.99h-3.98zm8.87-4.19V11c0-3.25-2.25-5.97-5.29-6.69v-.72C13.59 2.71 12.88 2 12 2s-1.59.71-1.59 1.59v.72C7.37 5.03 5.12 7.75 5.12 11v5.82L3 18.94V20h18v-1.06l-2.12-2.12zM16 13.01h-3v3h-2v-3H8V11h3V8h2v3h3v2.01z"></path>
                </svg>
              </span> */}
                {/* <div
                  onClick={handleWarning}
                  style={{
                    display: "flex",
                    background: "gray",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 10px",
                    borderRadius: "20px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      marginRight: "3px",
                      display: "inline-block",
                      fontSize: "15px",
                    }}
                  >
                    Try
                  </span>
                  <div className="container-logo-symbol">
                    <span>HD</span>
                  </div>
                  <span style={{ marginLeft: "3px", display: "inline-block" }}>
                    +
                  </span>
                </div> */}
                {/* <CreatePostDropdown /> */}
                {
                  <BtnSignDesktop
                    visible={visible}
                    handleVisible={handleVisibility}
                  />
                }
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/*   <Warning
        user="Hi Gabriel fono 🖐 👋 "
        description="Our team currently implementing these Features below"
        messages={messages}
        showWarning={showWarning}
        handleWarning={handleWarning}
        height="500px"
        name={user}
      /> */}
    </>
  );
};

export default NavBar;
