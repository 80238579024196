import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Error from "./pages/Error";

import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
export const blogContext = React.createContext();

function App() {
  const { user } = useAuth0();
  const TRACKING_ID = "G-1V5LNSLDX4";
  ReactGA.initialize(TRACKING_ID);
  return (
    <>
      <blogContext.Provider value={{}}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/team/" component={Team} />
          <Route component={Error} />
        </Switch>
      </blogContext.Provider>
    </>
  );
}

export default App;
