import React from "react";
import "./title.scss";

// export default function Title({ title, description }) {
//   return (
//     <div id="title" className="section-title">
//       <h1>{title}</h1>
//       <div></div>
//       <p>{description}</p>
//     </div>
//   );
// }

const Title = ({ title, specificTitle }) => {
  return (
    <div id="title" className="section-title">
      {specificTitle ? (
        <h2 style={{ margin: "1rem 0 1.5rem" }}>{title || "default title"}</h2>
      ) : (
        <h1 id="title2" style={{ margin: "1rem 0 1.5rem" }}>
          {title || "default title"}
        </h1>
      )}

      {/* <div className='underline'></div> */}
    </div>
  );
};

export default Title;
