import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import founder from "../../assets/seo_og_image/founder.jpg";
import { Helmet } from "react-helmet-async";
import SociauxLinks from "../../constant/SociauxLinks/SociauxLinks";
import batisseur from "../../assets/img/batisseur.jpg";
import cameroonFlag from "../../assets/img/cameroonFlag.png";
import usaFlag from "../../assets/img/usaFlag.jpeg";
import ReactGA from "react-ga4";
import Share from "../../components/Share";

import Title from "../../components/Title";
import "./Team.scss";

const Team = () => {
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Team page",
    });
  }, []);

  const initialValues = {
    email: "",
  };
  const [subscription, setSubscription] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [userEmail, setUserEmail] = useState({});
  const [error, setError] = useState(null);
  const [signed, setSigned] = useState("");

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setSubscription({ ...subscription, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // Additional logic when there are no form errors and the form is submitted
    }
  }, [formErrors]);

  const validate = (users) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!users.email) {
      errors.email = "Email is required!!!";
    } else if (!regex.test(users.email)) {
      errors.email = "This is not the valid email format!";
    }

    return errors;
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    setError(null);
    setFormErrors(validate(subscription));
    setIsSubmit(true);

    let email = subscription.email;
    /* const api_url = `/api/memberAdd/${email}`; */
    const api_url = `https://backend.ratemymajor.io/api/memberAdd/${email}`;

    fetch(api_url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.title === "Member Exists") {
          setSigned("You are already signed up for this email  😥 service.");
        } else if (data.title != "") {
          setSubscription({
            email: "", // Clearing the email field
            subscribe: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);

        if (
          error instanceof Response &&
          (error.status === 400 || error.status === 500)
        ) {
          setError(
            "An error occurred with the server. Please try again later."
          );
        }
      });
  };

  const [visible, setVisible] = useState(false);

  const handlevisible = () => {
    setVisible((prev) => {
      return !prev;
    });
  };
  return (
    <>
      <Helmet>
        <title>Founder | RateMyMajor.io</title>
        <link rel="canonical" href="/team" />
        <meta
          name="description"
          content="Meet Gabriel Fono, founder of RateMyMajor.io. Discover insights, reviews, and resources for informed education and career decisions. 🎓💼"
        />

        <meta
          name="keywords"
          content="Gabriel Andy Fono, RateMyMajor.io founder, software engineer, indie developer, Booz Allen Hamilton, current software engineer, cyber security engineer, digital online flipper, career guidance, student support, higher education insights, success stories"
        />

        <meta name="author" content="Gabriel Andy Fono" />

        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="Gabriel Andy Fono - Founder of RateMyMajor.io | Navigating Your Academic Journey for Success 🎓💡🚀"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Meet Gabriel Andy Fono, the visionary founder behind RateMyMajor.io. Navigate your academic journey with the insights of a current software engineer at Booz Allen Hamilton, indie developer, and cyber security expert. 🎓💡 Explore real-life reviews, gain career guidance, and connect with a supportive community. Dive into insightful blogs and tune in to podcasts featuring Gabriel's interviews about majors and careers. Your gateway to choosing the perfect major and shaping a successful future. 🌐📚🎙️"
        />

        {/* Replace 'url_to_your_thumbnail_image' with the actual image URL */}
        <meta property="og:image" content={founder} />
        {/* Replace 'url_to_your_page' with the actual page URL */}
        <meta property="og:url" content="https://ratemymajor.io/team" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content={founder} />
        <meta name="twitter:site" content="https://twitter.com/fono_gabriel" />

        <meta
          name="twitter:title"
          content="Gabriel Andy Fono - Founder of RateMyMajor.io | Navigating Your Academic Journey for Success 🎓💡🚀"
        />

        <meta
          name="twitter:description"
          content="Meet Gabriel Andy Fono, the visionary founder behind RateMyMajor.io. Navigate your academic journey with the insights of a current software engineer at Booz Allen Hamilton, indie developer, and cyber security expert. 🎓💡 Explore real-life reviews, gain career guidance, and connect with a supportive community. Dive into insightful blogs and tune in to podcasts featuring Gabriel's interviews about majors and careers. Your gateway to choosing the perfect major and shaping a successful future. 🌐📚🎙️"
        />

        {/* Replace 'url_to_your_thumbnail_image' with the actual image URL */}
        <meta name="twitter:image" content={founder} />
      </Helmet>
      <Layout visible={visible} handlevisible={handlevisible}>
        <section className="section">
          <Title title="RateMyMajor Team 🤝" />
          <div className="all-ceo-description-about">
            <div className="unique-employees-description-about">
              <div className="ceo-container-description-about">
                <h2 style={{ color: "#fff" }}>Gabriel Fono 🇨🇲 🇺🇸</h2>

                <h4>
                  🖥️ Software Engineer || 🧮 Mathematician || 🏗️ Civil Engineer
                  <br />
                  💻 Digital Online Flipper || 🕵️ Ethical Hacker <br />
                </h4>

                <p>
                  Founder & CEO Upcoming Platform
                  <a
                    style={{
                      marginLeft: ".5rem",
                      color: "#5aa3ed",
                      fontWeight: "bold", // Corrected typo here
                    }}
                    href="https://ratemymajor.io"
                  >
                    RateMyMajor.io
                  </a>
                </p>
              </div>

              <div className="ceo-picture-description-about">
                <div className="ceo-container-about">
                  <img src={batisseur} alt="Gabriel Andy Fono" />
                </div>
                <div className="container-sociaux-links-ceo-about">
                  <SociauxLinks />
                </div>
              </div>
            </div>

            <div className="ceo-background-about">
              <p>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Gabriel Fono
                </span>{" "}
                <span> is a dedicated Full-Time Software Engineer at</span>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  {" "}
                  Booz Allen Hamilton,
                </span>
                <span> working remotely 💻.</span>
                <br />
                <span>
                  <br />
                  He was born in a lively urban area in Cameroon and was raised
                  by his grandmother.
                </span>
                <span
                  style={{
                    fontSize: "10px",
                    width: "1% !important",
                    height: "1%!important",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "50%" }}
                    src={cameroonFlag}
                  />
                </span>{" "}
                <br />
                <span>
                  Even at a young age, Gabriel displayed a keen interest and
                  fascination with{" "}
                </span>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Mathematics
                </span>{" "}
                and{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Science along with a passionate enthusiasm for{" "}
                </span>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Video Games🎮🧠.
                </span>
                <br />
                <span>
                  <br />
                  After high school graduation, Gabriel faced difficulty
                  choosing a major. His parents recommended studying cardiology
                  to become a doctor, and he sought guidance from a school
                  counselor.
                </span>
                <br />
                <span>
                  <br />
                  After researching his future options, given his strong
                  background in math and physics, Gabriel initially pursued
                  physics for a few years but later switched to civil
                  engineering, a field he found enjoyable.
                </span>
                <br />
                <span>
                  He eventually began working as a structural engineer for an
                  international firm.
                </span>
                <br />
                <span>
                  <br />
                  In 2017, Gabriel moved to the USA and had a tough time getting
                  a civil engineering job because the United States has
                  different rules for foreign degrees 🌍🛫.
                </span>
                <br />
                <br />
                <span
                  style={{
                    display: "block",
                    margin: "2rem 0 ",
                    fontSize: "10px",
                    width: "1% !important",
                    height: "1%!important",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={usaFlag}
                  />
                </span>{" "}
                <span>
                  <br />
                  Gabriel finally got a job as a cabin cleaner supervisor🧹 at
                  Ground Services International located at Dulles International
                  Airport while studying ESL( English as second language) at his
                  local community college. Unfortunetely , his career didn't
                  align with his goals and what he had studied at the university
                  anymore.
                </span>
                <br />
                <span>
                  <br />
                  At some point on time, Gabriel didn't really enjoy his job
                  anymore.he started doing researching and talked to people who
                  studied different majors for him to figure out which path to
                  take. He discussed with people who graduated and have few
                  years of experience in cybersecurity, computer science, data
                  analysis, product management, nursing, civil engineering ,
                  Scrum master, teacher and so on.
                </span>
                <br />
                <span>
                  <br />
                  In addition, Gabriel did some extra research on Google,
                  Reddit, and Quora. 🕵️‍♂️ He wanted to find a platform or forum
                  where real graduates share their experiences about their major
                  and if it fits with their career goals and objectives. He
                  didn't find a perfect match, but some people did share their
                  experiences. 🗣️ Looking at the information, Gabriel thought
                  teaching himself coding could be the best option since he
                  already has a degree in physics and civil engineering.
                </span>
                <span>
                  <br />
                  So, he decided to learn coding on his own and eventually got a
                  job as a Full Stack Engineer at Booz Allen Hamilton 💻🚀.
                </span>
                <br />
                <span>
                  <br />
                  Picking a wrong major is a life decision that many high school
                  seniors face every year and this decision can mess up your
                  future big time. It's not just about losing money and time but
                  career. Switching to a new degree can be super tough 😰
                  Especially if you live in the Country where school is super
                  expensive like USA , Canada, England and so on . in Addition,
                  the mindset to study all over again can be very challenging
                  specially if you already have a life obligation such as family
                  and so on.
                </span>
                <br />
                <span>
                  <br />
                  Gabriel really wishes there were a platform where students who
                  finished school can share experiences about their major in
                  college🎓 and how that major had helped them succeed in life.
                  This way, other students can read these reviews and carefully
                  make smarter choices for their future🌟🤝.
                </span>
                <br />
                <span>
                  <br />
                  Something to always remember is that If you end up working in
                  a career you don't really enjoy, it can make you pretty
                  miserable 😞💼 for your rest of your life.
                </span>
                <br />
                <span>
                  <br />
                  Today, Gabriel is starting a project to create a platform that
                  can help students pick the right major for their future. 🚀📚
                  They can read reviews from graduates about their experiences
                  in the workforce 🎓💡.
                </span>
                <br />
                <span>
                  <br />
                  If you've already graduated, please share your experience on
                  this upcoming platform to help students make better decisions
                  for the future 🎓🌟.
                </span>
                <br />
                <span>
                  <br />
                  If you're still trying to figure out which major to choose, I
                  strongly recommend reading all the reviews on the platform as
                  soon as the platform is releazed.
                </span>
                <br />
                <span>
                  <br />
                  Your career 💼 is one of the most important decisions you'll
                  make in your entire life. You should be very carefully
                  considering your major.
                </span>
                <br />
                <span>
                  <br />
                  Gabriel is working on building the prototype beside his
                  current Full Time job that will be ready in the next two
                  weeks. 🚧🛠️
                </span>
                <br />
                <br />
                <span>
                  Our main goal is to : "Empowering graduates to share real-life
                  college experiences, guiding students through major
                  transitions, and assisting individuals returning to school in
                  finding the perfect major for their career journey based on
                  authentic community insights."
                </span>
                <br />
                <p>
                  <span>
                    You can send us feedback, suggestions and questions by
                    emailing us at{" "}
                    <a
                      rel="noreferrer"
                      style={{
                        color: "rgba(99,102,241,1)",
                        borderBottom: "rgba(147,197,253,1)",
                      }}
                      target="_blank"
                      title="Message me on gmail"
                      href="mailto:fonoandy@gmail.com?subject=Contact Request - fonoandy@gmail.com"
                    >
                      fonoandy@gmail.com
                    </a>
                  </span>
                  .
                  <br />{" "}
                  <span>
                    {" "}
                    Alternatively, you can send us a message on{" "}
                    <a
                      rel="noreferrer"
                      style={{
                        color: "rgba(99,102,241,1)",
                        borderBottom: "rgba(147,197,253,1)",
                      }}
                      target="_blank"
                      title="Message me on Twitter"
                      href="https://twitter.com/gabrielfono2"
                    >
                      Twitter
                    </a>{" "}
                    or{" "}
                    <a
                      rel="noreferrer"
                      style={{ color: "rgba(99,102,241,1)" }}
                      target="_blank"
                      title="Message me on Linkdin"
                      href="https://www.linkedin.com/in/gabriel-fono/"
                    >
                      LinkedIn
                    </a>
                  </span>
                  <br />
                  <br />
                  <span>
                    Stay tuned for the next updates ! 🎉 and dont forget to add
                    your email to be first to be notified as soon as we launch
                    the prototype on DEC 01/2023!.
                  </span>
                  <br />
                  <div>
                    <form onSubmit={handleSubscription}>
                      <span style={{ display: "block", fontWeight: "900" }}>
                        Join our waiting list and be the first to be notified
                        about our upcoming platform 📧
                      </span>
                      <Share message="Please Share on your social" />
                      {Object.keys(userEmail).length !== 0 ? (
                        <div className="successReview">{userEmail.message}</div>
                      ) : (
                        Object.keys(formErrors).length === 0 &&
                        isSubmit &&
                        (signed.length > 0 &&
                        signed ===
                          "You are already signed up for this email  😥 service." ? (
                          <h6
                            style={{
                              fontWeight: 900,
                              color: "red",
                            }}
                          >
                            Your email already existed 😥!!!
                          </h6>
                        ) : (
                          <h6
                            style={{
                              fontWeight: 900,
                              color: "var(--primaryColor)",
                            }}
                          >
                            Thanks 😊 , We just added you on our mailing list
                          </h6>
                        ))
                      )}
                      <input
                        style={{
                          height: "40px",
                          outline: "none",
                          fontSize: "16px",
                          width: "250px",
                          maxWidth: "50%",
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          textIndent: "10px",
                        }}
                        name="email"
                        placeholder="Enter your email 📧 ..."
                        type="email"
                        value={subscription.email}
                        onChange={handleChange}
                      />
                      <button className="btn-primary" type="submit">
                        Join Today
                      </button>
                      <h6 style={{ fontWeight: "800", color: "red" }}>
                        {formErrors.email}
                      </h6>
                      <h6 style={{ fontWeight: "800", color: "red" }}>
                        {error}
                      </h6>
                    </form>
                  </div>
                </p>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default Team;
