import React from "react";

const Authentification = ({ visible, logout, user, isAuthenticated }) => {
  return (
    <div
      className={
        visible
          ? " visible_container container_information_user_products "
          : "unvisible_container container_information_user_products"
      }
    >
      <span title="close button" className="close-btn">
        x
      </span>
      <div className="container_information_user_products_row1">
        <span>Welcome,</span>

        {isAuthenticated && <span> {user.given_name || user.nickname}</span>}
        <div className="nav-header">
          <a href="/" className="container-logo">
            <div className="container-logo-symbol">
              <span>RM</span>
            </div>
            <div className="container-logo-title">
              <span>RateMyMajor</span>
            </div>
          </a>
        </div>
      </div>

      <p className="container_information_user_products_row2">
        The ultimate Major Exploration Platform for to help students <br />
        Decide Their Major And Find Their Path For The Future. 🚀
      </p>
      {/* 
      <a
        href="/Dashboard/"
        className="container_information_user_products_row5 btn-primary"
      >
        dashboard
      </a> */}

      <a className="btn-primary" onClick={() => logout()}>
        logout
      </a>
    </div>
  );
};

export default Authentification;
